import React, { useEffect, useState } from "react"
import { Link as GatsbyLink } from "gatsby"
import LoadingPulse from "../LoadingSpinner/pulse"
// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({ children, to, activeClassName, partiallyActive, ...other }) => {

    const [loading, setLoading] = useState(false);

    const internal = /^\/(?!\/)/.test(to)

    useEffect(() => {
    }, [])

    // Use Gatsby Link for internal links, and <a> for others
    if (internal) {
        return (
            <>
                <GatsbyLink
                    to={to}
                    activeClassName={activeClassName}
                    partiallyActive={partiallyActive}
                    {...other}
                    onClick={(e) => {
                        
                    }}
                >
                    {children}
                </GatsbyLink>
            </>
        )
    }
    return (
        <a href={to} {...other}>
            {children}
        </a>
    )
}
export default Link